define("adept-iq/pods/components/form-components/addresses/component", ["exports", "ember-concurrency", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  const DEFAULT_DEBOUNCE_TIME = 1200;

  var _default = Ember.Component.extend({
    // Rename classNames to address and then separate that class into the actual trigger, dropdown content, and pin
    // want to give people a better css ruleset to override wherever this component is embedded
    classNames: ['locations-input'],
    classNameBindings: ['geocode.activeGeo'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    map: Ember.inject.service(),
    value: null,
    disabled: false,
    // default placeholder for search address
    placeholder: _placeholders.SEARCH_ADDRESS_PLACEHOLDER,
    validateLocationInBlockoutRegion: null,
    // used by blockout region for validation
    addressOptions: null,
    notesReadOnly: false,
    addressType: null,
    markerType: 'pick',
    address: null,
    showPinButton: true,
    renderInPlace: false,
    selectedAddress: null,
    addressRecord: null,
    disableAddress: false,
    locationsPath: 'latlng',
    activeGeo: Ember.computed.readOnly('geocode.activeGeo'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    streetNumberPath: Ember.computed.readOnly('extra.streetNumberPath'),
    streetAddressPath: Ember.computed.readOnly('extra.streetAddressPath'),
    premisePath: Ember.computed.readOnly('extra.premisePath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    freeformAddressPath: Ember.computed.readOnly('extra.freeformAddressPath'),
    defaultOptionsPath: Ember.computed.readOnly('extra.defaultOptionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    useRecordWithId: Ember.computed.readOnly('extra.useRecordWithId'),
    addressTypeOption: Ember.computed.readOnly('extra.addressType'),
    markerTypeOption: Ember.computed.readOnly('extra.markerTypeOption'),
    selectedAddressRecord: Ember.computed('valuePath', 'record', function () {
      const valuePath = this.get('valuePath');
      return this.get(`record.${valuePath}`) || this.get('record');
    }),
    isDisabledAddressSelect: Ember.computed('disabled', 'disableAddress', function () {
      const disabled = this.get('disabled');
      const disableAddress = this.get('disableAddress');
      return disabled || disableAddress;
    }),
    publicApi: null,

    init() {
      this._super(...arguments);

      this.setAddress();
      this.publicApi = {
        onValueChange: value => {
          this.send('setValue', value);
        }
      };
      const markerTypeOption = this.get('markerTypeOption');

      if (!Ember.isNone(markerTypeOption)) {
        this.set('markerType', markerTypeOption);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      const action = this.get('registerPublicApi');

      if (action) {
        action(this.publicApi);
      }
    },

    willDestroyElement() {
      const action = this.get('registerPublicApi');

      if (action) {
        action(null);
      }

      this._super(...arguments);
    },

    geocodeAddressChange() {
      const record = this.get('addressRecord');
      const address = this.get('geocode.address');
      const markerType = this.get('markerType');
      const validateLocationInBlockoutRegion = this.get('validateLocationInBlockoutRegion');

      if (markerType === 'speed-region' && Ember.isPresent('validateLocationInBlockoutRegion')) {
        if (validateLocationInBlockoutRegion(address.position)) {
          return;
        }
      }

      if (!Ember.isEmpty(address)) {
        this.convertTTAddressToModel(record, address).then(() => {
          this.disableAddressSearchOnMap(record, markerType);
        });
      }
    },

    saveReverseGeocodeAddress() {
      this.geocodeAddressChange();
      this.removeObserver('geocode.address', this.saveReverseGeocodeAddress);
    },

    validateAddresses() {
      const record = this.get('address');
      this.set('editModal.errors', []);

      if (record.get('location.content.lat') === 0 || record.get('location.content.lng') === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      }
    },

    setAddress() {
      const place = this.get('value'); // component expects a place model
      // then onchange method to mutate in parent
      // we should refactor this component where it can take any value and returns the address object
      // then the parent should decide what to do with that object would be better, in my opinion to be more inline with functional programming paradigm

      this.set('address', place);
      this.set('addressOptions', []);
      const selectedAddressRecord = this.get('selectedAddressRecord');

      if (selectedAddressRecord) {
        this.get('addressOptions').push(selectedAddressRecord);
      }
    },

    addressSearchResults(results) {
      results.forEach(result => {
        // benomad postalCode consist of cityCode and postal code V6L1C6/V6L. Sometimes postal code placed after city code
        const [pos1, pos2] = result.address.postalCode.trim().split('/');
        let postalCode;

        if (pos1.length > 3) {
          postalCode = pos1;
        } else if (Ember.isPresent(pos2)) {
          postalCode = pos2;
        } else {
          postalCode = pos1;
        }

        const [strPos1, strPos2] = result.address.street ? result.address.street.trim().split(', ') : ['', ''];
        const street = strPos2 ? strPos2 : strPos1;
        result.address.postalCode = postalCode;
        result.address.street = street;
      });
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(DEFAULT_DEBOUNCE_TIME);
      const result = yield this.get('geocode').geoCodingApi(term);
      this.addressSearchResults(result);
    }).restartable(),
    convertTTAddressToModel: function (record, ttAddress) {
      const useOptionRecord = this.get('useOptionRecord');
      const geocode = this.get('geocode') || [];
      const markerType = this.get('markerType');
      const valuePath = this.get('locationsPath');
      const addressTypeOption = this.get('addressTypeOption');
      const options = {
        record,
        valuePath,
        useOptionRecord,
        addressTypeOption
      };
      this.set('record.isForceDirty', true);
      record.set('isForceDirty', true);
      options.valuePath = 'address.zoneName';
      this.get('onChange')(null, options);
      this.get('onChange')(cleanFieldString(ttAddress.position), options);
      options.valuePath = this.get('streetNumberPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.streetNumber), options);
      options.valuePath = this.get('premisePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.premise), options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
      options.valuePath = this.get('streetAddressPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.street), options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.city), options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.state), options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.country), options);

      if (this.get('freeformAddressPath')) {
        options.valuePath = this.get('freeformAddressPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.freeformAddress), options);
      }

      options.valuePath = 'location.lat';
      this.get('onChange')(ttAddress.position.lat, options);
      options.valuePath = 'location.lng';
      this.get('onChange')(ttAddress.position.lon, options);

      if (Ember.isPresent(ttAddress.position.geoNode)) {
        options.valuePath = 'location.geoNode';
        this.get('onChange')(ttAddress.position.geoNode, options);
      }

      this.cursorWait();
      return this.get('geocode').calculateZone(ttAddress.position.lat, ttAddress.position.lon).then(result => {
        if (!Ember.isNone(result) && !Ember.isEmpty(result.zone.zoneName) && result.zone.zoneName > '0') {
          if (record.get('isForceDirty')) {
            const zoneName = result.zone.zoneName ? this.get('store').peekAll('zone-type').findBy('id', result.zone.zoneName) : null;
            const zName = result.zone.zoneName ? zoneName.name : null;
            this.set('address.address.zoneName', zoneName);
            this.set('address.address.zName', zName);
            options.valuePath = 'address.zoneName';
            this.get('onChange')(zoneName, options);
          }
        } else {
          this.clearAddressModel(record);
          this.get('notifications').warning(`CAN'T GET ZONE FOR ${ttAddress.address.freeformAddress}`);
        }

        this.cursorDefault();
        geocode.activateGeocode(false, record, markerType);
        this.validateAddresses();
        this.set('addressOptions', ttAddress);
      });
    },
    clearAddressModel: function (record) {
      const useOptionRecord = this.get('useOptionRecord');
      const valuePath = this.get('streetNumberPath');
      const addressTypeOption = this.get('addressTypeOption');
      const options = {
        record,
        valuePath,
        useOptionRecord,
        addressTypeOption
      };
      this.get('onChange')('', options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('freeformAddressPath');
      this.get('onChange')('', options);
      options.valuePath = 'location.lat';
      this.get('onChange')('', options);
      options.valuePath = 'location.lng';
      this.get('onChange')('', options);
      options.valuePath = 'location.geoNode';
      this.get('onChange')('', options);
      record.set('lat', 0);
      record.set('lng', 0);
    },

    enableAddressSearchOnMap(record, markerType) {
      Ember.$('html,body,.tomtom-map,#speed-region').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record, markerType);
      this.set('addressRecord', record);
      this.addObserver('geocode.address', this.geocodeAddressChange);
    },

    disableAddressSearchOnMap(record, markerType) {
      Ember.$('html,body,.tomtom-map,#speed-region').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, markerType);
      this.set('addressRecord', null);
      this.removeObserver('geocode.address', this.geocodeAddressChange);
    },

    /**
     * address's position contains empty lat, lng, and lon .. need to check all values empty..
     * Though correct address is selected, saveNewGeocode  is throwing error;
     * @param record
     * @returns {boolean}
     */
    isEmptyPosition(record) {
      const position = record.get('address.position');
      return Ember.isEmpty(position) || !position.lat && !position.lng && !position.lon;
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    actions: {
      setValue(value) {
        this.set('value', value);
        this.setAddress();
      },

      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        if (this.get('useRecordWithId') && !Ember.isNone(value.get('id'))) {
          this.set('record.place', value);
          this.set('addresses', [value]);
        } else {
          this.convertTTAddressToModel(record, value).then(() => {
            // reposition flag
            if (!this.isEmptyPosition(record)) {
              this.get('geocode').saveNewGeocode(record.get('address.position'), value);
            }
          }).then(() => {
            // onChange is called for each field. this function is invoked after all fields are changed
            const onRecordChanged = this.get('onRecordChanged');

            if (onRecordChanged) {
              onRecordChanged(value);
            }
          });
        }
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeo');
        const markerType = this.get('markerType');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, markerType);
        } else {
          this.enableAddressSearchOnMap(record, markerType);
        }
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        const text = e.target.value;
        const options = {
          record,
          valuePath: 'streetAddress'
        };
        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      },

      onDropDownOpen() {
        this.set('addressOptions', []);
      }

    }
  });

  _exports.default = _default;
});