define("adept-iq/pods/components/form-components/places/component", ["exports", "ember-concurrency", "adept-iq/utils/zone-validation"], function (_exports, _emberConcurrency, _zoneValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Component.extend({
    classNames: ['form-components-places'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    map: Ember.inject.service(),
    value: null,
    disabled: false,
    addressOptions: null,
    display: null,
    addressType: null,
    addresses: Ember.computed.readOnly('display'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    premisePath: Ember.computed.readOnly('extra.premisePath'),
    streetNumberPath: Ember.computed.readOnly('extra.streetNumberPath'),
    streetAddressPath: Ember.computed.readOnly('extra.streetAddressPath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    subLocalityPath: Ember.computed.readOnly('extra.subLocalityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    locationsPath: Ember.computed.readOnly('extra.locationsPath'),
    tomtomFormattedAddressPath: Ember.computed.readOnly('extra.tomtomFormattedAddressPath'),
    freeformAddressPath: Ember.computed.readOnly('extra.freeformAddressPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    addressRecord: null,
    selected: null,

    init() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this.get('geocode').deactivateGeocode();

      this._super(...arguments);
    },

    geocodeAddressChange() {
      const record = this.get('addressRecord');
      const address = this.get('geocode.address');

      if (!Ember.isEmpty(address)) {
        this.convertTTAddressToModel(record, address).then(() => {
          this.disableAddressSearchOnMap();
        });
      }
    },

    validateAddresses() {
      const records = this.get('addresses') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (record.get('place.location.lat') === 0 || record.get('place.location.lng') === 0) {
          this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
        }
      });
    },

    addressSearchResults(results) {
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      const result = yield this.get('geocode').geoCodingApi(term);
      this.addressSearchResults(result);
    }),
    isAddressAtMax: Ember.computed('addresses.[]', function () {
      const length = this.get('addresses').length;
      let isAtMax = false;

      if (this.get('field.addressType') === 'primary' && length >= 1) {
        isAtMax = true;
      }

      return isAtMax;
    }),
    convertTTAddressToModel: function (record, ttAddress) {
      const useOptionRecord = this.get('useOptionRecord');
      const valuePath = this.get('streetNumberPath');
      const options = {
        record,
        valuePath,
        useOptionRecord
      };

      if (ttAddress.address && ttAddress.address.freeformAddress) {
        return this.get('geocode').calculateZone(ttAddress.position.lat, ttAddress.position.lon).then(result => {
          if (!Ember.isNone(result)) {
            this.set('record.isForceDirty', true);
            this.get('onChange')(cleanFieldString(ttAddress.address.streetNumber), options);
            options.valuePath = this.get('streetAddressPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
            options.valuePath = this.get('localityPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
            options.valuePath = this.get('subLocalityPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
            options.valuePath = this.get('regionPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.countrySubdivisionName), options);
            options.valuePath = this.get('subRegionPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.countrySecondarySubdivision), options);
            options.valuePath = this.get('postalCodePath');
            this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
            options.valuePath = this.get('countryPath');
            this.get('onChange')(cleanFieldString(ttAddress.address.countryCode), options);

            if (this.get('freeformAddressPath')) {
              options.valuePath = this.get('freeformAddressPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.freeformAddress), options);
            }

            record.set('place.location.lat', ttAddress.position.lat);
            record.set('place.location.lng', ttAddress.position.lon);
            this.validateAddresses();
          } else {
            this.get('notifications').warning(`CAN'T GET ZONE FOR ${ttAddress.address.freeformAddress}`);
            return;
          }
        });
      }
    },
    clearAddressModel: function (record) {
      const valuePath = this.get('streetNumberPath');
      const options = {
        record,
        valuePath
      };
      this.get('onChange')('', options);
      options.valuePath = this.get('streetAddressPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('subLocalityPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')('', options);
      record.set('place.content.address.content.locations.firstObject.lat', 0);
      record.set('place.content.address.content.locations.locations.firstObject.lng', 0);
    },

    enableAddressSearchOnMap(record) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record, 'pick');
      this.set('addressRecord', record);
      this.addObserver('geocode.address', this.geocodeAddressChange);
    },

    disableAddressSearchOnMap() {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').deactivateGeocode();
      this.set('addressRecord', null);
      this.removeObserver('geocode.address', this.geocodeAddressChange);
    },

    actions: {
      onInput(record, valuePath, value) {
        const useOptionRecord = this.get('useOptionRecord');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        const boundingBox = this.get('map.boundingBox'); // if the point is outside the valid zone poolygon
        // return error and exit

        if (!(0, _zoneValidation.insideZone)([value.position.lat, value.position.lon], boundingBox)) {
          this.get('notifications').warning('Invalid Zone');
          return;
        }

        this.convertTTAddressToModel(record, value);
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap();
        } else {
          this.enableAddressSearchOnMap(record);
        }
      },

      onAddNewAddress(record, valuePath, modelName) {
        const placeType = this.get('field.addressType');
        const riderPlaceType = this.get('store').peekRecord('rider-place-type', placeType);
        const newRiderPlace = this.get('editModal').pushRecordValue(record, valuePath, modelName);
        newRiderPlace.set('riderPlaceType', riderPlaceType);
        newRiderPlace.set('rider', record);
        const place = this.get('editModal').addRecordValue(newRiderPlace, 'place', 'place');
        const address = this.get('editModal').addRecordValue(place, 'address', 'address');
        const location = this.get('editModal').addRecordValue(place, 'location', 'location');
        newRiderPlace.set('place', place);
        address.get('locations').pushObject(location);
      },

      onRemoveAddress(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        record.deleteRecord();
        this.get('onRemove')(value, options);
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        const text = e.target.value;
        const options = {
          record,
          valuePath: 'streetAddress'
        }; // address has been forced without geocodes
        // this.set('record.isForceDirty', true);

        this.clearAddressModel(record); // this.set('record.isForceDirty', true);

        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      }

    }
  });

  _exports.default = _default;
});